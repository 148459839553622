import React, { useState, useEffect } from "react";
import homeoffer1 from "../Assets/advertisementbanner.webp";
import homeoffer2 from "../Assets/advertisementbanner2.webp";

import "./offercarousel.css";

const OfferCarousel = () => {
  const slides = [
    {
      image: homeoffer1,
      heading: "Indulge with Massage at Home",
      subheading: "Experience relaxation like never before with a professional massage at home. Enjoy the ultimate comfort and personalized care without leaving your house. Book now for a stress-relieving session.",
      buttonText: "Buy Now"
    },
    {
      image: homeoffer2,
      heading: "Expert Tutor at Your Fingertips",
      subheading: "We offers instant access to professional guidance and support, providing personalized learning solutions and expert advice whenever and wherever you need it, right from your device.",
      buttonText: "Learn More"
    }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <div className="offer-carousel">
      <div className="carousel-header">
        <h1 className="carousel-heading">{slides[currentIndex].heading}</h1>
        <p className="carousel-subheading">{slides[currentIndex].subheading}</p>
        <button className="carousel-button" onClick={() => alert('Button clicked!')}>
          {slides[currentIndex].buttonText}
        </button>
      </div>
      <div
        className="carousel-images"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {slides.map((slide, index) => (
          <img
            key={index}
            src={slide.image}
            alt={`Slide ${index + 1}`}
            className="carousel-image"
          />
        ))}
      </div>
      <div className="carousel-dots">
        {slides.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? "active" : ""}`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default OfferCarousel;
