import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "./AddressPopup.css";

const AddressPopup = ({ isOpen, onRequestClose, address = {}, onSave }) => {
  const [newAddress, setNewAddress] = useState({
    name: address.name || "",
    houseNumber: address.houseNumber || "",
    areaStreet: address.areaStreet || "",
    city: address.city || "",
    state: address.state || "",
    pincode: address.pincode || "",
    type: address.type || "", // 'home' or 'office'
  });

  useEffect(() => {
    setNewAddress({
      name: address.name || "",
      houseNumber: address.houseNumber || "",
      areaStreet: address.areaStreet || "",
      city: address.city || "",
      state: address.state || "",
      pincode: address.pincode || "",
      type: address.type || "", // 'home' or 'office'
    });
  }, [address]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewAddress((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = () => {
    if (!newAddress.city || !newAddress.state) {
      alert("City/Town and State fields are required.");
      return;
    }
    onSave(newAddress);
    const fullAddress = `${newAddress.name} ${newAddress.houseNumber} ${newAddress.areaStreet} ${newAddress.city} ${newAddress.state} - ${newAddress.pincode}`;
    onSave(fullAddress);
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Address Modal"
      className="address-modal-content"
      overlayClassName="modal-overlay"
    >
      <h2>Edit Address</h2>
      <div className="address-form">
        <div className="input-group">
          <input
            type="text"
            name="name"
            placeholder="Address Name"
            value={newAddress.name}
            onChange={handleChange}
          />
          <input
            type="text"
            name="houseNumber"
            placeholder="House Number"
            value={newAddress.houseNumber}
            onChange={handleChange}
          />
        </div>
        <div className="input-group">
          <input
            type="text"
            name="areaStreet"
            placeholder="Area and Street"
            value={newAddress.areaStreet}
            onChange={handleChange}
          />
          <input
            type="text"
            name="city"
            placeholder="City/Town/District*"
            value={newAddress.city}
            onChange={handleChange}
            required
          />
        </div>
        <div className="input-group">
          <input
            type="text"
            name="state"
            placeholder="State*"
            value={newAddress.state}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="pincode"
            placeholder="Pincode*"
            value={newAddress.pincode}
            onChange={handleChange}
            required
          />
        </div>
        <div className="checkbox-container">
          <label>
            <input
              type="radio"
              name="type"
              value="home"
              checked={newAddress.type === "home"}
              onChange={handleChange}
            />
            Home
          </label>
          <label>
            <input
              type="radio"
              name="type"
              value="office"
              checked={newAddress.type === "office"}
              onChange={handleChange}
            />
            Office
          </label>
        </div>
        <div>
          <button onClick={handleSave}>Save</button>
          <button className="cancel" onClick={onRequestClose}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddressPopup;
