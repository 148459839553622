import React, { createContext, useState, useContext } from "react";

const ServiceProviderContext = createContext();

export const ServiceProviderProvider = ({ children }) => {
  const [serviceProvider, setServiceProvider] = useState(null);

  return (
    <ServiceProviderContext.Provider
      value={{ serviceProvider, setServiceProvider }}
    >
      {children}
    </ServiceProviderContext.Provider>
  );
};

export const useServiceProvider = () => useContext(ServiceProviderContext);
