import React, { useState, useEffect } from "react";
import {
  FaUser,
  FaCalendarAlt,
  FaLanguage,
  FaHistory,
  FaWallet,
  FaQuestionCircle,
  FaSignOutAlt,
} from "react-icons/fa";
import { useAuth } from "../../contexts/AuthContext";
import { firebaseDB } from "../../firebase";
import "./ProfileDashboard.css";
import moment from "moment";
import Modal from "react-modal";
import FAQSection from "./FAQSection.js";
import AddressPopup from "../AddressPopup.js";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
const ProfileDashboard = () => {
  const [activeTab, setActiveTab] = useState("profile");
  const { currentUser } = useAuth();
  const [userInfo, setUserInfo] = useState({
    name: "",
    phone: "",
    email: "",
    dob: "",
    address1: "",
    address2: "",
    address3: "",
    profileImage: "",
  });
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [greeting, setGreeting] = useState("Good Morning,"); // Initial greeting
  const [bookings, setBookings] = useState({
    "2024-08-09": ["Booking 1 at 10:00 AM", "Booking 2 at 12:00 PM"],
    "2024-08-10": ["Booking 3 at 02:00 PM"],
  });
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal
  const [hasChanges, setHasChanges] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [currentAddressField, setCurrentAddressField] = useState("");
  const bookingData = {
    "2024-08-29": [
      {
        serialNumber: 1,
        serviceName: "Service 1",
        providerName: "Provider A",
        date: "28 August",
        time: "10:00 AM",
        duration: "1 hrs",
        price: "50",
        location: "Location 1",
        status: "Confirmed",
      },
      {
        serialNumber: 2,
        serviceName: "Service 2",
        providerName: "Provider B",
        date: "28 August",
        time: "12:00 PM",
        duration: "2 hrs",
        price: "100",
        location: "Location 2",
        status: "Pending",
      },
    ],
    "2024-08-10": [
      {
        serialNumber: 1,
        serviceName: "Service 3",
        providerName: "Provider C",
        date: "2024-08-10",
        time: "02:00 PM",
        duration: "1.5 hours",
        price: "$75",
        location: "Location 3",
        status: "Completed",
      },
    ],
  };
  useEffect(() => {
    // Update greeting based on time of day
    const updateGreeting = () => {
      const hour = new Date().getHours();
      if (hour < 12) {
        setGreeting("Good Morning,");
      } else if (hour < 18) {
        setGreeting("Good Afternoon,");
      } else if (hour < 24) {
        setGreeting("Good Evening,");
      } else {
        setGreeting("Good Night,");
      }
    };

    updateGreeting(); // Initial update
    const interval = setInterval(updateGreeting, 60000); // Update every minute

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  useEffect(() => {
    if (currentUser) {
      const userRef = firebaseDB.ref(`users/${currentUser.uid}`);
      userRef.on("value", (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setUserInfo((prevState) => ({
            ...prevState,
            ...data,
            name: data.name || currentUser.displayName,
            email: data.email || currentUser.email,
          }));
        } else {
          setUserInfo((prevState) => ({
            ...prevState,
            name: currentUser.displayName,
            email: currentUser.email,
          }));
        }
      });

      return () => userRef.off(); // Cleanup subscription on unmount
    }
  }, [currentUser]);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setHasChanges(true);
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const profileImage = reader.result;
        setUserInfo((prevState) => ({
          ...prevState,
          profileImage,
        }));
        setHasChanges(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    if (!userInfo.address1) {
      alert("Address 1 is required.");
      return;
    }

    if (currentUser) {
      const userRef = firebaseDB.ref(`users/${currentUser.uid}`);
      await userRef.update({
        ...userInfo,
        name: currentUser.displayName,
        email: currentUser.email,
      });
      alert("Profile updated successfully!");
      setHasChanges(false);
    }
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const handleLanguageModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const closeModal = () => {
    setIsModalOpen(false); // Close modal
  };
  const openAddressModal = (field) => {
    setCurrentAddressField(field);
    setIsAddressModalOpen(true);
  };

  const closeAddressModal = () => {
    setIsAddressModalOpen(false);
  };

  const handleAddressSave = (fullAddress) => {
    setUserInfo((prevState) => ({
      ...prevState,
      [currentAddressField]: fullAddress,
    }));
    setHasChanges(true);
    setIsAddressModalOpen(false);
  };
  const handleLogout = async () => {
    try {
      await signOut(auth);
      alert("Logged out successfully!");
      // Redirect or perform any additional actions after logout
    } catch (error) {
      console.error("Error logging out: ", error);
      alert("Error logging out. Please try again.");
    }
  };
  const renderContent = () => {
    switch (activeTab) {
      case "profile":
        return (
          <div className="profile-content">
            <h2>Profile</h2>
            <h1>Basic Details</h1>
            <div className="profile-details">
              <div className="profile-info">
                <div className="input-row">
                  <div className="info-row">
                    <div className="info-field">
                      <label>Name</label>
                      <input
                        type="text"
                        name="name"
                        value={userInfo.name}
                        onChange={handleChange}
                        disabled
                      />
                    </div>
                    <div className="info-field">
                      <label>Phone</label>
                      <input
                        type="text"
                        name="phone"
                        value={userInfo.phone}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="info-row">
                    <div className="info-field">
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        value={userInfo.email}
                        onChange={handleChange}
                        disabled
                      />
                    </div>
                    <div className="info-field">
                      <label>D.O.B.</label>
                      <input
                        type="date"
                        name="dob"
                        value={userInfo.dob}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="info-row">
                    <div className="info-field image-upload">
                      <div
                        className="image-preview"
                        onClick={() =>
                          document.getElementById("fileUpload").click()
                        }
                      >
                        {userInfo.profileImage ? (
                          <img
                            src={userInfo.profileImage}
                            alt="Profile"
                            style={{
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <div className="blank-image">Upload Image</div>
                        )}
                      </div>
                      <input
                        id="fileUpload"
                        type="file"
                        onChange={handleFileUpload}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h1 style={{ marginTop: "40px" }}>Address Details</h1>
            <div className="profile-details">
              <div className="profile-info">
                <div className="input-row">
                  <div className="info-row">
                    <div
                      className="info-field address"
                      onClick={() => openAddressModal("address1")}
                    >
                      <label>Address 1 *</label>
                      <textarea
                        name="address1"
                        value={userInfo.address1 || ""}
                        readOnly
                        style={{
                          lineHeight: "1.2",
                          paddingBottom: "4px",
                          fontSize: "14px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="info-row">
                    <div
                      className="info-field address"
                      onClick={() => openAddressModal("address2")}
                    >
                      <label>Address 2</label>
                      <textarea
                        name="address2"
                        value={userInfo.address2}
                        readOnly
                        style={{
                          lineHeight: "1.2",
                          paddingBottom: "4px",
                          fontSize: "14px",
                        }}
                      />
                    </div>
                  </div>
                  <div className="info-row">
                    <div
                      className="info-field address"
                      onClick={() => openAddressModal("address3")}
                    >
                      <label>Address 3</label>
                      <textarea
                        name="address3"
                        value={userInfo.address3}
                        readOnly
                        style={{
                          lineHeight: "1.2",
                          paddingBottom: "4px",
                          fontSize: "14px",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {hasChanges && (
                <button className="login-button" onClick={handleSave}>
                  Save
                </button>
              )}
            </div>
            <Modal
              isOpen={isAddressModalOpen}
              onRequestClose={closeAddressModal}
              contentLabel="Address Modal"
              className="modal-content"
              overlayClassName="modal-overlay"
            >
              <AddressPopup
                isOpen={isAddressModalOpen}
                onRequestClose={closeAddressModal}
                address={userInfo}
                onSave={(fullAddress) => {
                  setUserInfo((prevState) => ({
                    ...prevState,
                    [currentAddressField]: fullAddress,
                  }));
                  setHasChanges(true);
                }}
              />
            </Modal>
          </div>
        );
      case "bookings":
        return (
          <div className="bookings-content">
            <h2 className="left-aligned">Upcoming Bookings</h2>
            <h1
              className="left-aligned"
              style={{ fontWeight: "500", fontSize: "26px" }}
            >
              {selectedDate === moment().format("YYYY-MM-DD")
                ? "Today"
                : moment(selectedDate).format("dddd, D MMMM")}
            </h1>
            <div className="date-buttons">
              {[...Array(7)].map((_, index) => {
                const date = moment().add(index, "days").format("YYYY-MM-DD");
                return (
                  <button
                    key={date}
                    onClick={() => handleDateClick(date)}
                    className={selectedDate === date ? "active-date" : ""}
                  >
                    {moment(date).format("D ddd")}
                  </button>
                );
              })}
            </div>
            <div className="bookings-list">
              {bookingData[selectedDate] &&
              bookingData[selectedDate].length > 0 ? (
                <table className="booking-table">
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Service Name</th>
                      <th>Provider's Name</th>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Duration</th>
                      <th>Price</th>
                      <th>Location</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookingData[selectedDate].map((booking, index) => (
                      <tr key={index}>
                        <td>{booking.serialNumber}</td>
                        <td>{booking.serviceName}</td>
                        <td>{booking.providerName}</td>
                        <td>{booking.date}</td>
                        <td>{booking.time}</td>
                        <td>{booking.duration}</td>
                        <td className="price-cell">{booking.price}</td>
                        <td className="location-cell">{booking.location}</td>
                        <td
                          className={`status-cell ${booking.status.toLowerCase()}`}
                        >
                          <div
                            className={`status-circle status-circle-${booking.status.toLowerCase()}`}
                          />
                          <span
                            className={`status-${booking.status.toLowerCase()}`}
                          >
                            {booking.status}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="no-bookings">NO BOOKINGS</div>
              )}
            </div>
          </div>
        );
      case "appLanguage":
        return null;
      case "changePassword":
        return <div>Change Password Content</div>;
      case "pastActivity":
        return (
          <div className="past-activity-content">
            <h2>Past Activity</h2>
            <table className="activity-table">
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>Service Name</th>
                  <th>Provider Name</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Duration</th>
                  <th>Location</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="8">No past activity</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      case "wallet":
        return (
          <div className="past-activity-content">
            <h2>Wallet</h2>
            <button className="balance">₹70/-</button>
            <table className="activity-table">
              <thead>
                <tr>
                  <th>S. No</th>
                  <th>Type</th>
                  <th>From / For</th>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Amount</th>
                  <th>Medium</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan="8">No Transaction Made</td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      case "faqs":
        return <FAQSection />;
      default:
        return <div>Select an option</div>;
    }
  };

  return (
    <div className="dashboard-container">
      <div className="sidebar">
        <div>
          <h2>{greeting}</h2>
          <h1>{currentUser ? currentUser.displayName : "User"}</h1>
        </div>
        <ul>
          <li
            className={activeTab === "profile" ? "active" : ""}
            onClick={() => setActiveTab("profile")}
          >
            <FaUser className="icon" /> Profile
          </li>
          <li
            className={activeTab === "bookings" ? "active" : ""}
            onClick={() => setActiveTab("bookings")}
          >
            <FaCalendarAlt className="icon" /> Bookings
          </li>
          <li
            className={activeTab === "appLanguage" ? "active" : ""}
            onClick={handleLanguageModal}
          >
            <FaLanguage className="icon" /> App Language
          </li>
          {/* <li
            className={activeTab === "changePassword" ? "active" : ""}
            onClick={() => setActiveTab("changePassword")}
          >
            <FaKey className="icon" /> Change Password
          </li> */}
          <li
            className={activeTab === "pastActivity" ? "active" : ""}
            onClick={() => setActiveTab("pastActivity")}
          >
            <FaHistory className="icon" /> Past Activity
          </li>
          <li
            className={activeTab === "wallet" ? "active" : ""}
            onClick={() => setActiveTab("wallet")}
          >
            <FaWallet className="icon" /> Wallet
          </li>
          <li
            className={activeTab === "faqs" ? "active" : ""}
            onClick={() => setActiveTab("faqs")}
          >
            <FaQuestionCircle className="icon" /> FAQs
          </li>
          <li className="logout-item">
            <button className="logout-button" onClick={handleLogout}>
              <FaSignOutAlt className="icon" /> Logout
            </button>
          </li>
        </ul>
      </div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="App Language"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h2>App Language</h2>
        <p>Select Your Language</p>
        <div className="language-options">
          <label className="language-option">
            English
            <input type="radio" name="language" value="English" />
            <span className="checkmark"></span>
          </label>
          <label className="language-option">
            Hindi
            <input type="radio" name="language" value="Hindi" />
            <span className="checkmark"></span>
          </label>
        </div>
        <button className="continue-button" onClick={closeModal}>
          Continue
        </button>
      </Modal>
      <div className="content-profile">{renderContent()}</div>
    </div>
  );
};

export default ProfileDashboard;
