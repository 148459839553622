import React from "react";
// import homeoffer from "../Assets/home_offer.png";
// import service from "../Assets/service.png";
import servicevideo from "../Assets/service.mp4";
import service21 from "../Assets/mainservice1.webp";
import service22 from "../Assets/mainservice2.webp";
import service23 from "../Assets/mainservice3.webp";
import service24 from "../Assets/mainservice4.webp";
import service25 from "../Assets/mainservice5.webp";
import service26 from "../Assets/mainservice6.webp";
import service27 from "../Assets/mainservice7.webp";
import service28 from "../Assets/mainservice8.webp";
import service29 from "../Assets/mainservice9.webp";
import service20 from "../Assets/mainservice10.webp";
import OverallCard from "./overall-cards";
function Overall() {
  const cards = [
    {
      image: service21,
      heading: "Sofa Cleaning",
      description: "Get a Spotless Sofa",
      btn1Text: "Schedule Service",
      btn2Text: "Buy Now",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service22,
      heading: "TV Repair",
      description: "Fix Your TV Today",
      btn1Text: "Schedule Service",
      btn2Text: "Buy Now",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service25,
      heading: "House Cleaning",
      description: "Refresh Your Home",
      btn1Text: "Schedule Service",
      btn2Text: "Buy Now",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service24,
      heading: "Refrigerator Repair",
      description: "Schedule a Repair Now",
      btn1Text: "Schedule Service",
      btn2Text: "Buy Now",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service26,
      heading: "Interior Decoration",
      description: "Design Your Dream Home",
      btn1Text: "Schedule Service",
      btn2Text: "Buy Now",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service23,
      heading: "W Machine Repair",
      description: "Restore Your Washing Machine",
      btn1Text: "Schedule Service",
      btn2Text: "Buy Now",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service27,
      heading: "Baby Day Care",
      description: "Join Our Day Care Family",
      btn1Text: "Schedule Service",
      btn2Text: "Buy Now",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service28,
      heading: "Taxi Booking",
      description: "Book Your Ride Now",
      btn1Text: "Schedule Service",
      btn2Text: "Buy Now",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service29,
      heading: "Maid Services",
      description: "Find a Trusted Maid",
      btn1Text: "Schedule Service",
      btn2Text: "Buy Now",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service20,
      heading: "Tutoring Sevices",
      description: "Boost Your Grades",
      btn1Text: "Schedule Service",
      btn2Text: "Buy Now",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
  ];
  return (
    <div>
      <section className="home-offer" style={{ marginTop: "100px" }}>
        <video
          style={{ width: "90vw", height: "80vh", objectFit: "cover" }}
          autoPlay
          loop
          muted
        >
          <source src={servicevideo} type="video/mp4" />
        </video>
      </section>
      <section style={{ width: "86vw", margin: "0 auto" }}>
        <h2 style={{ fontSize: "2rem", fontWeight: "600", marginTop: "40px" }}>
          Quality Service, Guaranteed Satisfaction!
        </h2>
        <OverallCard cards={cards} />
      </section>
    </div>
  );
}

export default Overall;
