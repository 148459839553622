import React from "react";
import homeoffer from "../Assets/advertisementbanner.webp";
import CardCarousel from "./card-carousel";
import service1 from "../Assets/servicetype1.webp";
import service2 from "../Assets/servicetype2.webp";
import service3 from "../Assets/servicetype3.webp";
import service4 from "../Assets/servicetype4.webp";
// import service from "../Assets/service.png"
import service21 from "../Assets/mainservice1.webp";
import service22 from "../Assets/mainservice2.webp";
import service23 from "../Assets/mainservice3.webp";
import service24 from "../Assets/mainservice4.webp";
import service25 from "../Assets/mainservice5.webp";
import service26 from "../Assets/mainservice6.webp";
import reviews from "../Assets/reviews.png";
import "./pesonal.css";
import { FaSearch } from "react-icons/fa";
function Personal() {
  const cards = [
    {
      image: service21,
      heading: "Sofa Cleaning",
      description: "From ₹1163.63/mo for 24 mos at 15% Interest or",
      btn1Text: "Schedule Service",
      btn2Text: "Buy Now",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service22,
      heading: "TV Repair",
      description: "From ₹1163.63/mo for 24 mos at 15% Interest or",
      btn1Text: "Schedule Service",
      btn2Text: "Buy Now",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service25,
      heading: "House Cleaning",
      description: "From ₹1163.63/mo for 24 mos at 15% Interest or",
      btn1Text: "Schedule Service",
      btn2Text: "Buy Now",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service24,
      heading: "Refrigirator Repair",
      description: "From ₹1163.63/mo for 24 mos at 15% Interest or",
      btn1Text: "Schedule Service",
      btn2Text: "Buy Now",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service26,
      heading: "Interior Decoration",
      description: "From ₹1163.63/mo for 24 mos at 15% Interest or",
      btn1Text: "Schedule Service",
      btn2Text: "Buy Now",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service23,
      heading: "W Machine Repair",
      description: "From ₹1163.63/mo for 24 mos at 15% Interest or",
      btn1Text: "Schedule Service",
      btn2Text: "Buy Now",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    
    
    
  ];

  return (
    <div>
      <section className="home-offer" style={{ marginTop: "50px" }}>
        <img src={homeoffer} alt="Offer" />
      </section>
      <section
        className="home-services"
        style={{ width: "80%", margin: "80px auto 80px auto" }}
      >
        <h2>Your One-Stop Solution for Home Maintenance!</h2>
        <section className="service-container">
          <div className="service-left-panel">
            <div className="service-filter-section">
              <h2 className="service-filter-heading">Filter</h2>
              <FaSearch
                className="service-filter-icon"
                style={{ color: "black" }}
              />
            </div>
            <div className="service-subcategories">
              <h3>Subcategories</h3>
              <hr />
              <ul>
                <li>
                  <a href="/wiring-installation">Wiring installation</a>
                </li>
                <li>
                  <a href="/electrical-panel-upgrades">
                    Electrical panel upgrades
                  </a>
                </li>
                <li>
                  <a href="/lighting-installation">Lighting installation</a>
                </li>
                <li>
                  <a href="/outlet-switch-repair">Outlet and switch repair</a>
                </li>
                <li>
                  <a href="/circuit-breaker-replacement">
                    Circuit breaker replacement
                  </a>
                </li>
                <li>
                  <a href="/ceiling-fan-installation">
                    Ceiling fan installation
                  </a>
                </li>
                <li>
                  <a href="/home-automation-setup">Home automation setup</a>
                </li>
                <li>
                  <a href="/generator-installation">Generator installation</a>
                </li>
                <li>
                  <a href="/security-system-installation">
                    Security system installation
                  </a>
                </li>
                <li>
                  <a href="/electrical-inspections">Electrical inspections</a>
                </li>
              </ul>
            </div>
            <div
              className="service-subcategories"
              style={{ paddingTop: "10px" }}
            >
              <h3>Customize Package</h3>
              <hr />
              <ul>
                <li>
                  <a href="/custom-package">Make your own package</a>
                </li>
              </ul>
            </div>
            <div
              className="service-subcategories"
              style={{ paddingTop: "10px" }}
            >
              <h3>Bestseller</h3>
              <hr />
              <ul>
                <li>
                  <a href="/best-services">Best Services</a>
                </li>
                <li>
                  <a href="/best-services">Best service providers</a>
                </li>
                <li>
                  <a href="/best-services">Offers</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="service-right-panel">
          <div className="service-card" style={{ backgroundImage: `url(${service1})` }}>
  <div className="service-card-content">
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <div className="service-card-text">
          <h4>Fall in Love with Your Home Again</h4>
          {/* <h5>Card Subheading</h5> */}
          <p>
            Card description goes here. This is a brief description
            of the service.
          </p>
        </div>
        <img
          src={reviews}
          alt="Review"
          className="service-card-review-image"
        />
      </div>
      <div className="btns-group">
        <button className="btns1">Schedule Service</button>
        <button className="btns2">Buy Now</button>
      </div>
    </div>
  </div>
</div>
<div className="service-card" style={{ backgroundImage: `url(${service2})` }}>
  <div className="service-card-content">
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <div className="service-card-text">
          <h4>Fall in Love with Your Home Again</h4>
          {/* <h5>Card Subheading</h5> */}
          <p>
            Card description goes here. This is a brief description
            of the service.
          </p>
        </div>
        <img
          src={reviews}
          alt="Review"
          className="service-card-review-image"
        />
      </div>
      <div className="btns-group">
        <button className="btns1">Schedule Service</button>
        <button className="btns2">Buy Now</button>
      </div>
    </div>
  </div>
</div>
<div className="service-card" style={{ backgroundImage: `url(${service3})` }}>
  <div className="service-card-content">
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <div className="service-card-text">
          <h4>Fall in Love with Your Home Again</h4>
          {/* <h5>Card Subheading</h5> */}
          <p>
            Card description goes here. This is a brief description
            of the service.
          </p>
        </div>
        <img
          src={reviews}
          alt="Review"
          className="service-card-review-image"
        />
      </div>
      <div className="btns-group">
        <button className="btns1">Schedule Service</button>
        <button className="btns2">Buy Now</button>
      </div>
    </div>
  </div>
</div>
<div className="service-card" style={{ backgroundImage: `url(${service4})` }}>
  <div className="service-card-content">
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <div className="service-card-text">
          <h4>Fall in Love with Your Home Again</h4>
          {/* <h5>Card Subheading</h5> */}
          <p>
            Card description goes here. This is a brief description
            of the service.
          </p>
        </div>
        <img
          src={reviews}
          alt="Review"
          className="service-card-review-image"
        />
      </div>
      <div className="btns-group">
        <button className="btns1">Schedule Service</button>
        <button className="btns2">Buy Now</button>
      </div>
    </div>
  </div>
</div>
          </div>
        </section>
      </section>
      <section
        className="home-services"
        style={{ width: "80%", margin: "80px auto 80px auto" }}
      >
        <h2>Quality Service, Guaranteed Satisfaction!</h2>
        <CardCarousel cards={cards} />
      </section>
    </div>
  );
}

export default Personal;
