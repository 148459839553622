import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import homeoffer from "../Assets/home_offer.png";
// import service from "../Assets/service.png";
import servicevideo from "../Assets/service.mp4";
import service21 from "../Assets/mainservice1.webp";
import bridalservice from "../Assets/bridal.jpeg";
import service22 from "../Assets/mainservice2.webp";
import service23 from "../Assets/mainservice3.webp";
import service24 from "../Assets/mainservice4.webp";
import service25 from "../Assets/mainservice5.webp";
import service26 from "../Assets/mainservice6.webp";
import service27 from "../Assets/mainservice7.webp";
import service28 from "../Assets/mainservice8.webp";
import service29 from "../Assets/mainservice9.webp";
import service20 from "../Assets/mainservice10.webp";
import ParticularCard from "./particular-card";
import "./particularservice.css";

function ParticularService() {
  const navigate = useNavigate();
  const [selected, setSelected] = useState("female");

  const handleClick = (value) => {
    setSelected(value);
  };
  const cards = [
    {
      image: bridalservice,
      name: "Bridal Makeup",
      heading: "Services Starting from ₹299/-",
      btn1Text: "Schedule Service",
      btn1Action: () => navigate("/product-page"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service21,
      name: "Party Makeup",
      heading: "Services Starting from ₹299/-",
      btn1Text: "Schedule Service",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service21,
      name: "Hair Styling",
      heading: "Services Starting from ₹299/-",
      btn1Text: "Schedule Service",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service21,
      name: "Manicure",
      heading: "Services Starting from ₹299/-",
      btn1Text: "Schedule Service",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service21,
      name: "Pedicure",
      heading: "Services Starting from ₹299/-",
      btn1Text: "Schedule Service",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service21,
      name: "Facial Treatments",
      heading: "Services Starting from ₹299/-",
      btn1Text: "Schedule Service",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service21,
      name: "Hair Coloring",
      heading: "Services Starting from ₹299/-",
      btn1Text: "Schedule Service",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service21,
      name: "Haircuts",
      heading: "Services Starting from ₹299/-",
      btn1Text: "Schedule Service",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service21,
      name: "Waxing",
      heading: "Services Starting from ₹299/-",
      btn1Text: "Schedule Service",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
    {
      image: service21,
      name: "Eyebrow Threading",
      heading: "Services Starting from ₹299/-",
      btn1Text: "Schedule Service",
      btn1Action: () => alert("Button 1 clicked on Card 1"),
      btn2Action: () => alert("Button 2 clicked on Card 1"),
    },
  ];
  return (
    <div>
      <section style={{ width: "86vw", margin: "0 auto", marginTop: "160px" }}>
        <div className="ps-header">
          <div className="ps-head">
            <h2>Beauticians Services</h2>
            <p>Your Go-To Platform for Prioritized Services</p>
          </div>
          <div className="toggle-container">
            <button
              className={`toggle-button ${
                selected === "female" ? "active" : ""
              }`}
              onClick={() => handleClick("female")}
            >
              Female
            </button>
            <button
              className={`toggle-button ${selected === "male" ? "active" : ""}`}
              onClick={() => handleClick("male")}
            >
              Male
            </button>
          </div>
        </div>
        <hr />
        <ParticularCard cards={cards} />
      </section>
    </div>
  );
}

export default ParticularService;
