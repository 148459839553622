import React, { useState } from "react";
import "./about.css";
// import aboutimg from "../../Assets/home_bg.jpg";
import aboutvideo from "../../Assets/about.mp4";
import prem from "../../Assets/prem.webp";
import rambhatia from "../../Assets/rambhatia.webp";
import sagar from "../../Assets/sagar.webp";
import ayush from "../../Assets/ayush.webp";
import vishal from "../../Assets/vishal.webp";
import chander from "../../Assets/chander.webp";
import "./card.css";
import { FaLinkedin, FaTwitter, FaFacebook, FaInstagram } from "react-icons/fa";
function About() {
  const Card = ({ data, handleImageClick }) => {
    return (
      <div className="card-team">
        <div className="image-container-team">
          <img
            src={data.image}
            alt={`${data.name}'s`}
            onClick={() => handleImageClick(data.id)}
            className="card-image-team"
          />
        </div>
        <div className="card-content-team">
          <h3>{data.name}</h3>
          <p className="designation-team">{data.designation}</p>
          <p className="bio-team">{data.bio}</p>
          <div className="social-links-team">
            {data.socialLinks.map((link, index) => (
              <a
                key={index}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.icon}
              </a>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const CardGrid = ({ cardData, handleImageClick }) => {
    return (
      <div className="card-grid">
        {cardData.map((card) => (
          <Card key={card.id} data={card} handleImageClick={handleImageClick} />
        ))}
      </div>
    );
  };
  const cardData = [
    {
      id: 1,
      name: "Dr. Prem Pankaj",
      designation: "CEO",
      bio: "",
      image: prem, // Sample image URL
      socialLinks: [
        {
          name: "LinkedIn",
          url: "https://www.linkedin.com/in/ayush83461/",
          icon: <FaLinkedin />,
        },
        // {
        //   name: "Twitter",
        //   url: "https://www.twitter.com",
        //   icon: <FaTwitter />,
        // },
        {
          name: "Facebook",
          url: "https://www.facebook.com/ayush.thakur.16940599",
          icon: <FaFacebook />,
        },
        {
          name: "Instagram",
          url: "https://www.instagram.com/ayush83461",
          icon: <FaInstagram />,
        },
      ],
    },
    // {
    //   id: 2,
    //   name: "Ram Bhatia",
    //   designation: "COO",
    //   bio: "",
    //   image: rambhatia,
    //   socialLinks: [
    //     {
    //       name: "LinkedIn",
    //       url: "https://www.linkedin.com",
    //       icon: <FaLinkedin />,
    //     },
    //     {
    //       name: "Twitter",
    //       url: "https://www.twitter.com",
    //       icon: <FaTwitter />,
    //     },
    //     {
    //       name: "Facebook",
    //       url: "https://www.facebook.com",
    //       icon: <FaFacebook />,
    //     },
    //     {
    //       name: "Instagram",
    //       url: "https://www.instagram.com",
    //       icon: <FaInstagram />,
    //     },
    //   ],
    // },
    {
      id: 2,
      name: "Mr. Ayush Thakur",
      designation: "CTO",
      bio: "",
      image: ayush,
      socialLinks: [
        {
          name: "LinkedIn",
          url: "https://www.linkedin.com",
          icon: <FaLinkedin />,
        },
        // {
        //   name: "Twitter",
        //   url: "https://www.twitter.com",
        //   icon: <FaTwitter />,
        // },
        {
          name: "Facebook",
          url: "https://www.facebook.com",
          icon: <FaFacebook />,
        },
        {
          name: "Instagram",
          url: "https://www.instagram.com",
          icon: <FaInstagram />,
        },
      ],
    },
  ];
  const cardData2 = [
    {
      id: 1,
      name: "Mr. Vishal Singh",
      designation: "Web Developer",
      bio: "",
      image: vishal,
      socialLinks: [
        {
          name: "LinkedIn",
          url: "https://in.linkedin.com/in/vishalsinghsliet",
          icon: <FaLinkedin />,
        },
        {
          name: "Twitter",
          url: "https://vishal-singh-kangra.web.app",
          icon: <FaTwitter />,
        },
        {
          name: "Facebook",
          url: "https://vishal-singh-kangra.web.app",
          icon: <FaFacebook />,
        },
        {
          name: "Instagram",
          url: "https://www.instagram.com/vxshal.s",
          icon: <FaInstagram />,
        },
      ],
    },
    {
      id: 2,
      name: "Mr. Sandeep Kumar",
      designation: "App Developer",
      bio: "",
      image: rambhatia,
      socialLinks: [
        {
          name: "LinkedIn",
          url: "https://www.linkedin.com",
          icon: <FaLinkedin />,
        },
        {
          name: "Twitter",
          url: "https://www.twitter.com",
          icon: <FaTwitter />,
        },
        {
          name: "Facebook",
          url: "https://www.facebook.com",
          icon: <FaFacebook />,
        },
        {
          name: "Instagram",
          url: "https://www.instagram.com",
          icon: <FaInstagram />,
        },
      ],
    },
    {
      id: 3,
      name: "Mr. Sagar Awasthi",
      designation: "Graphic Designer",
      bio: "",
      image: sagar,
      socialLinks: [
        {
          name: "LinkedIn",
          url: "https://www.linkedin.com",
          icon: <FaLinkedin />,
        },
        {
          name: "Twitter",
          url: "https://www.twitter.com",
          icon: <FaTwitter />,
        },
        {
          name: "Facebook",
          url: "https://www.facebook.com",
          icon: <FaFacebook />,
        },
        {
          name: "Instagram",
          url: "https://www.instagram.com",
          icon: <FaInstagram />,
        },
      ],
    },
  ];
  const cardData3 = [
    {
      id: 1,
      name: "Ms. Muskan Khan",
      designation: "Customer Executive",
      bio: "",
      image: rambhatia,
      socialLinks: [
        // {
        //   name: "LinkedIn",
        //   url: "https://in.linkedin.com/in",
        //   icon: <FaLinkedin />,
        // },
        // {
        //   name: "Facebook",
        //   url: "https://facebook.com",
        //   icon: <FaFacebook />,
        // },
        // {
        //   name: "Instagram",
        //   url: "https://www.instagram.com",
        //   icon: <FaInstagram />,
        // },
      ],
    },
  ];
  const cardData4 = [
    {
      id: 1,
      name: "Ms. Chander Rekha",
      designation: "Operations",
      bio: "",
      image: chander,
      socialLinks: [
        // {
        //   name: "LinkedIn",
        //   url: "https://in.linkedin.com/in",
        //   icon: <FaLinkedin />,
        // },
        // {
        //   name: "Facebook",
        //   url: "https://facebook.com",
        //   icon: <FaFacebook />,
        // },
        // {
        //   name: "Instagram",
        //   url: "https://www.instagram.com",
        //   icon: <FaInstagram />,
        // },
      ],
    },
  ];
  const card2s = [
    { heading: "6+", description: "Cities we are in" },
    { heading: "900+", description: "Service providers we have" },
    { heading: "50+", description: "Services are offering" },
    { heading: "100+", description: "Services provided till now" },
  ];
  const [selectedOption, setSelectedOption] = useState(1);
  const renderComponent = () => {
    const handleImageClick = (option, rowIndex, colIndex) => {
      // Redirect to another page or perform an action
      console.log(
        `Redirecting to Option ${option} - Row ${rowIndex} - Col ${colIndex}`
      );
      // Example redirection
      // window.location.href = '/some-path';
    };

    switch (selectedOption) {
      case 1:
        return (
          <CardGrid cardData={cardData} handleImageClick={handleImageClick} />
        );
      case 2:
        return (
          <CardGrid cardData={cardData2} handleImageClick={handleImageClick} />
        );
      case 3:
        return (
          <CardGrid cardData={cardData4} handleImageClick={handleImageClick} />
        );
      case 4:
        return <div>Member details will be available shortly—stay tuned!</div>;
      case 5:
        return (
          <CardGrid cardData={cardData3} handleImageClick={handleImageClick} />
        );
      default:
        return null;
    }
  };
  const [expandedSection, setExpandedSection] = useState(null);
  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };
  return (
    <>
      <section
        style={{ width: "85%", margin: "160px auto 120px auto" }}
        className="about-section"
      >
        <div className="about-head">
          <h2>Welcome to RAPS APP</h2>
          <p>Your Go-To Platform for Prioritized Services</p>
        </div>
        <div className="about-class-subsection">
          <div className="about-column">
            <video className="about-image" autoPlay loop muted>
              <source src={aboutvideo} type="video/mp4" />
            </video>
            <p className="about-paragraph">
              At RAPS APP, we understand that in today’s fast-paced world, time
              is of the essence. Whether you need urgent plumbing repairs, a
              last-minute beauty appointment, or immediate tech support, our
              mission is to provide you with quick and efficient access to
              prioritized services at the touch of a button.
            </p>
            <div className="about-cards">
              {card2s.map((card2, index) => (
                <div className="card2" key={index}>
                  <h3>{card2.heading}</h3>
                  <p>{card2.description}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="about-other-content">
            <div className="about-sub-column1">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <h2 className="about-heading">Our Vision</h2>{" "}
                <span
                  className={`toggle-icon ${
                    expandedSection !== "vision" ? "expanded" : ""
                  }`}
                  onClick={() => toggleSection("vision")}
                >
                  {expandedSection === "vision" ? "-" : "+"}
                </span>
              </div>
              <p
                className={`about-paragraph ${
                  expandedSection !== "vision" ? "show" : ""
                }`}
              >
                To be the leading platform that transforms the way people access
                and prioritize essential services, ensuring convenience,
                reliability, and exceptional quality in every interaction.
              </p>
            </div>
            <div className="about-sub-column2">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <h2 className="about-heading" style={{ color: "white" }}>
                  Our Mission
                </h2>
                <span
                  className={`toggle-icon ${
                    expandedSection === "mission" ? "expanded" : ""
                  }`}
                  onClick={() => toggleSection("mission")}
                >
                  {expandedSection === "mission" ? "-" : "+"}
                </span>
              </div>
              <p
                className={`about-paragraph ${
                  expandedSection === "mission" ? "show" : ""
                }`}
              >
                <p>
                  Our mission at RAPS APP is to provide seamless and prioritized
                  access to top-rated professionals for all your urgent service
                  needs. We strive to:
                </p>
                <ol className="about-list">
                  <li>
                    Connect customers with highly skilled and vetted
                    professionals who deliver outstanding service.
                  </li>
                  <li>
                    Enhance the user experience with a user-friendly app that
                    simplifies the booking process and ensures prompt service
                    delivery.
                  </li>
                  <li>
                    Innovate continuously to expand our service offerings and
                    integrate advanced technology for efficient and reliable
                    solutions.
                  </li>
                  <li>
                    Empower our service providers by offering them a platform to
                    showcase their skills and reach a wider audience.
                  </li>
                  <li>
                    Commit to excellence by maintaining the highest standards of
                    quality, professionalism, and customer satisfaction.
                  </li>
                </ol>
                <p className="about-paragraph">
                  With RAPS APP, we aim to save you time, reduce stress, and
                  provide peace of mind by ensuring that your urgent needs are
                  met with priority and precision.
                </p>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        style={{ width: "85%", margin: "80px auto 80px auto" }}
        className="about-section"
      >
        <div className="about2-container">
          <div className="about2-first-div">
            <p>Your Quickest Path to </p>
            <h2>
              Quality Services, Anytime, <span color="#9999A1">Anywhere !</span>
            </h2>
          </div>
          <div className="about2-second-div">
            <h2>Who We Are</h2>
            <p>
              RAPS APP was founded on the belief that everyone deserves access
              to quality services without the long wait times. Our founders, a
              team of seasoned professionals from the tech and service
              industries, came together with a common goal: to revolutionize the
              way people access essential services. With a focus on speed,
              reliability, and excellence, RAPS APP has quickly become a trusted
              name in service delivery.
            </p>
            <h2>What We Offer</h2>
            <p>
              RAPS APP offers a wide range of services, all available with
              prioritized scheduling. Our categories include, but are not
              limited to:
            </p>
            <ul>
              <li>
                <span style={{ fontWeight: "bolder" }}>Home Cleaning: </span>
                Thorough and professional cleaning services tailored to your
                specific needs.
              </li>
              <li>
                <span style={{ fontWeight: "bolder" }}>Plumbing: </span>
                Expert plumbers ready to fix leaks, unclog drains, and handle
                any emergency repairs.
              </li>
              <li>
                <span style={{ fontWeight: "bolder" }}>Beauty Services: </span>
                From hairstyling to manicures, our beauty professionals bring
                the salon experience to you.
              </li>
              <li>
                <span style={{ fontWeight: "bolder" }}>
                  Electrical Services:{" "}
                </span>
                Qualified electricians for all your repair, installation, and
                maintenance needs.
              </li>
              <li>
                <span style={{ fontWeight: "bolder" }}>Tech Support: </span>
                Immediate assistance for your tech-related issues, from device
                setup to troubleshooting.
              </li>
              <li>
                <span style={{ fontWeight: "bolder" }}>Pest Control: </span>
                Efficient pest control services to ensure your home is safe and
                pest-free.
              </li>
              <li>
                <span style={{ fontWeight: "bolder" }}>Carpentry: </span>
                Skilled carpenters for custom furniture, repairs, and
                installations.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section
        style={{ width: "85%", margin: "80px auto 80px auto" }}
        className="about-section"
      >
        <div className="about-head">
          <h2>Why Choose RAPS APP?</h2>

          <p>
            Our dedicated team of professionals is passionate about making your
            life easier. From our experienced technicians to our friendly
            customer service representatives, everyone at RAPS APP is committed
            to ensuring your complete satisfaction.
          </p>
        </div>
        <div className="about-cards-col3">
          <div className="about-card3">
            <p>
              <span style={{ fontWeight: "bolder" }}>
                Prioritized Service:{" "}
              </span>
              We understand the urgency of your needs, which is why our platform
              is designed to provide fast, prioritized service.
            </p>
          </div>
          <div className="about-card3">
            <p>
              <span style={{ fontWeight: "bolder" }}>
                Quality Professionals:{" "}
              </span>
              Our service providers are thoroughly vetted, highly skilled, and
              committed to delivering exceptional service.
            </p>
          </div>
          <div className="about-card3">
            <p>
              <span style={{ fontWeight: "bolder" }}>User-Friendly: </span>
              Our app is easy to use, allowing you to book services quickly and
              efficiently.
            </p>
          </div>
        </div>
      </section>
      <section
        style={{ width: "85%", margin: "80px auto 80px auto" }}
        className="about-section"
      >
        <div className="about-head-mid">
          <h2>Let’s meet our team</h2>
          <p>
            Our dedicated team of professionals is passionate about making your
            life easier. From our experienced technicians to our friendly
            customer service representatives, everyone at RAPS APP is committed
            to ensuring your complete satisfaction.
          </p>
        </div>
        <div className="options">
          <button
            className={selectedOption === 1 ? "active" : ""}
            onClick={() => setSelectedOption(1)}
          >
            Executives
          </button>
          <button
            className={selectedOption === 2 ? "active" : ""}
            onClick={() => setSelectedOption(2)}
          >
            Technical Team
          </button>
          <button
            className={selectedOption === 3 ? "active" : ""}
            onClick={() => setSelectedOption(3)}
          >
            Operations Team
          </button>
          <button
            className={selectedOption === 4 ? "active" : ""}
            onClick={() => setSelectedOption(4)}
          >
            Marketing Team
          </button>
          <button
            className={selectedOption === 5 ? "active" : ""}
            onClick={() => setSelectedOption(5)}
          >
            Customer Team
          </button>
        </div>
        <div className="componentContainer">{renderComponent()}</div>
      </section>
    </>
  );
}

export default About;
