import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDzh3Hw0V9y_RTm1cVLFsL8Q8TUPBGw4VI",
  authDomain: "rapsappweb-4f4b6.firebaseapp.com",
  databaseURL: "https://rapsappweb-4f4b6-default-rtdb.firebaseio.com/",
  projectId: "rapsappweb-4f4b6",
  storageBucket: "rapsappweb-4f4b6.appspot.com",
  messagingSenderId: "986933971064",
  appId: "1:986933971064:web:52b25e76d88746db74458d",
  measurementId: "G-26NYS21HW0",
};

// Initialize Firebase (only if it hasn't been initialized yet)
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// Set the persistence to LOCAL
firebase
  .auth()
  .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  .then(() => {
    console.log("Persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Error setting persistence:", error);
  });

// Exporting auth and database instances
const auth = firebase.auth();
const firebaseDB = firebase.database();

export { auth, firebaseDB };
export default firebase;

// Function to get the current user
export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      unsubscribe();
      if (user) {
        resolve(user);
      } else {
        reject(new Error("No user logged in"));
      }
    }, reject);
  });
};
