import React, { useState, useEffect } from "react";
import "./service-provider-auth.css";
import { auth, firebaseDB } from "../firebase";
import GoogleLogo from "../Assets/googlelogo.png";
import logo from "../Assets/RAPSAPP.png";
import signupImage from "../Assets/login.webp";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import {
  GoogleAuthProvider,
  signInWithRedirect,
  getRedirectResult,
} from "firebase/auth";
import "react-toastify/dist/ReactToastify.css";
import { ref, set } from "firebase/database";
import { useServiceProvider } from "../contexts/ServiceProviderContext";

function ServiceProviderAuth({ show, handleClose }) {
  const [isLogin, setIsLogin] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const { setServiceProvider } = useServiceProvider();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleFormSwitch = () => {
    setIsLogin(!isLogin);
    setIsForgotPassword(false);
  };

  const notifyError = (error) => {
    let message;
    switch (error.code) {
      case "auth/invalid-email":
        message = "The email address is not valid. Please enter a valid email.";
        break;
      case "auth/user-disabled":
        message =
          "This user account has been disabled. Please contact support.";
        break;
      case "auth/user-not-found":
        message = "No user found with this email address.";
        break;
      case "auth/wrong-password":
        message = "Incorrect password. Please try again.";
        break;
      case "auth/email-already-in-use":
        message =
          "This email is already registered. Please use a different email.";
        break;
      case "auth/weak-password":
        message =
          "Your password is too weak. Please choose a stronger password.";
        break;
      default:
        message = error.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const email = document.getElementById("providerLoginEmail").value;
    const password = document.getElementById("providerLoginPassword").value;

    if (!email || !password) {
      notifyError({ code: "auth/missing-fields" });
      return;
    }

    try {
      await auth.signInWithEmailAndPassword(email, password);
      const user = await auth.currentUser;
      if (user) {
        setServiceProvider({
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
        });
        window.location.href = `/more-details?uid=${user.uid}`;
      }
    } catch (error) {
      console.error("Error signing in:", error.code, error.message);
      notifyError(error);
    }
  };

  const handleSignup = async (event) => {
    event.preventDefault();
    const name = document.getElementById("providerSignupName").value;
    const email = document.getElementById("providerSignupEmail").value;
    const password = document.getElementById("providerSignupPassword").value;

    if (!name || !email || !password) {
      notifyError({ message: "All fields are mandatory" });
      return;
    }

    try {
      const userCredential = await auth.createUserWithEmailAndPassword(
        email,
        password
      );
      const user = userCredential.user;
      if (user) {
        await user.updateProfile({ displayName: name });
        await set(ref(firebaseDB, "serviceproviders/" + user.uid), {
          name: name,
          email: email,
        });

        // Update the service provider context
        setServiceProvider({ uid: user.uid, email: email, displayName: name });
        window.location.href = `/more-details?uid=${user.uid}`;
      }
    } catch (error) {
      console.error("Error signing up:", error.message);
      notifyError(error);
    }
  };

  const handleForgotPassword = async (event) => {
    event.preventDefault();
    const email = document.getElementById("providerForgotPasswordEmail").value;

    if (!email) {
      notifyError({ message: "Email is required" });
      return;
    }

    try {
      await auth.sendPasswordResetEmail(email);
      toast.success("Password reset email sent", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsForgotPassword(false);
    } catch (error) {
      console.error("Error sending password reset email:", error.message);
      notifyError(error);
    }
  };

  const handleGoogleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithRedirect(auth, provider);
  };

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    getRedirectResult(auth)
      .then((result) => {
        if (result) {
          const user = result.user;
          setServiceProvider({
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
          });
          window.location.href = `/more-details?uid=${user.uid}`; // Redirect to more details page
        }
      })
      .catch((error) => {
        console.error("Error during Google sign-in:", error.message);
        notifyError(error);
      });
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <>
      <ToastContainer />
      <div className="service-provider-auth-overlay" onClick={handleClose}>
        <div
          className="service-provider-auth-modal"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="service-provider-auth-body">
            <div className="service-provider-auth-row">
              <div className="service-provider-auth-image-col">
                <img
                  src={signupImage}
                  className="service-provider-auth-image"
                  alt="Signup"
                />
              </div>
              <div className="service-provider-auth-form-col">
                <img
                  src={logo}
                  className="service-provider-auth-logo"
                  alt="Logo"
                />
                <h3>
                  {isLogin
                    ? "Welcome back! Glad to see you, Again!"
                    : "Complete your registration"}
                </h3>
                {isForgotPassword ? (
                  <form
                    style={{ width: "100%" }}
                    onSubmit={handleForgotPassword}
                  >
                    <div className="form-group">
                      <input
                        type="email"
                        id="providerForgotPasswordEmail"
                        placeholder="Enter email"
                      />
                    </div>
                    <button type="submit" className="btn-primary-nav">
                      Send Reset Link
                    </button>
                    <p
                      className="mt-3"
                      style={{ textAlign: "left", width: "100%" }}
                    >
                      <span
                        className="toggle-form"
                        onClick={() => setIsForgotPassword(false)}
                      >
                        Back to Login
                      </span>
                    </p>
                  </form>
                ) : isLogin ? (
                  <form style={{ width: "100%" }} onSubmit={handleLogin}>
                    <div className="form-group">
                      <input
                        type="email"
                        id="providerLoginEmail"
                        placeholder="Enter email"
                      />
                    </div>
                    <div className="password-input-container">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="providerLoginPassword"
                        placeholder="Password"
                      />
                      <span
                        className="password-toggle-icon"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                    <button type="submit" className="btn-primary-nav">
                      Login
                    </button>
                    <p
                      className="mt-3"
                      style={{ textAlign: "left", width: "100%" }}
                    >
                      Forgot Password?
                      <span
                        className="toggle-form"
                        onClick={() => setIsForgotPassword(true)}
                        style={{ marginLeft: "5px" }}
                      >
                        Click to Reset
                      </span>
                    </p>
                  </form>
                ) : (
                  <form style={{ width: "100%" }} onSubmit={handleSignup}>
                    <div className="form-group">
                      <input
                        type="text"
                        id="providerSignupName"
                        placeholder="Enter full name"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        id="providerSignupEmail"
                        placeholder="Enter email"
                      />
                    </div>
                    <div className="password-input-container">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="providerSignupPassword"
                        placeholder="Password"
                      />
                      <span
                        className="password-toggle-icon"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                    <button type="submit" className="btn-primary-nav">
                      Sign Up
                    </button>
                    {/* <p
                      className="mt-3"
                      style={{ textAlign: "left", width: "100%" }}
                    >
                      <span className="toggle-form" onClick={handleFormSwitch}>
                        Back to Login
                      </span>
                    </p> */}
                  </form>
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <hr
                    style={{
                      flex: 1,
                      height: "1px",
                      backgroundColor: "black",
                      margin: "0 10px",
                    }}
                  />
                  <p className="text-center mt-3">Or Sign In With</p>
                  <hr
                    style={{
                      flex: 1,
                      height: "1px",
                      backgroundColor: "black",
                      margin: "0 10px",
                    }}
                  />
                </div>
                <div className="social-icons">
                  <div
                    style={{
                      border: "0.5px solid #E8ECF4",
                      borderRadius: "4px",
                      padding: "8px 12px",
                      cursor: "pointer",
                    }}
                    onClick={handleGoogleSignIn}
                  >
                    <img
                      src={GoogleLogo}
                      alt="Google Logo"
                      style={{ width: "32px" }}
                      className="social-icon"
                    />
                  </div>
                </div>
                <p className="text-center mt-3">
                  {isLogin ? "New User?" : "Already have an account?"}{" "}
                  <span className="toggle-form" onClick={handleFormSwitch}>
                    {isLogin ? "Sign Up as Provider" : "Sign In as Provider"}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceProviderAuth;
