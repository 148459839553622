import React, { useState, useEffect } from "react";
import {
  RiArrowDropDownLine,
  RiArrowLeftSLine,
  RiArrowRightSLine,
} from "react-icons/ri";
import "./home-carousel.css";
import homebg1 from "../Assets/hero1.webp";
import homebg2 from "../Assets/hero2.webp";
const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slides = [
    {
      title: "Explore What’s Coming Soon",
      description: "Our team is creating exciting new services. Stay tuned for previews and explore our innovative solutions soon!",
      backgroundImage: homebg1,
      dropdowns: [
        {
          p: "I am looking for",
          label: "Select Service",
          options: ["Home Maintianance", "Personal Services" , "Education" , "Household Services" ,  "Health and Wellness Services" , "Professional Services" , "Travel and Hospitality"],
        },
        {
          p: "I am looking at",
          label: "Select Location",
          options: ["NA"],
        },
      ],
    },
    {
      title: "Be the First to Experience",
      description: "We’re thrilled to be launching services soon! Stay tuned for all the exciting details!",
      backgroundImage: homebg2,
      dropdowns: [
        {
          p: "I am looking for",
          label: "Select Service",
          options: ["Home Maintianance", "Personal Services" , "Education" , "Household Services" ,  "Health and Wellness Services" , "Professional Services" , "Travel and Hospitality"],
        },
        {
          p: "I am looking at",
          label: "Select Location",
          options: ["NA"],
        },
      ],
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [slides.length]);

  const handlePrev = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const handleNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const currentSlide = slides[currentIndex];

  return (
    <section
      className="home-section"
      style={{
        position: "relative",
        backgroundImage: `url(${currentSlide.backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="content">
        <div className="left-half">
          <h2>{currentSlide.title}</h2>
          <p>{currentSlide.description}</p>

          <div className="dropdowns">
            <div className="dropdown-row">
              <div className="dropdown">
                <label>{currentSlide.dropdowns[0].p}</label>
                <div className="dropdown-container">
                  <select style={{textDecoration:'none'}}>
                    <option disabled selected value="">
                      {currentSlide.dropdowns[0].label}
                    </option>
                    {currentSlide.dropdowns[0].options.map((option, idx) => (
                      <option value={`option${idx + 1}`} key={idx}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <RiArrowDropDownLine className="dropdown-icon" />
                </div>
              </div>
              <div className="vertical-line"></div>
              <div className="dropdown">
                <label>{currentSlide.dropdowns[1].p}</label>
                <div className="dropdown-container">
                  <select>
                    <option disabled selected value="">
                      {currentSlide.dropdowns[1].label}
                    </option>
                    {currentSlide.dropdowns[1].options.map((option, idx) => (
                      <option value={`option${idx + 1}`} key={idx}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <RiArrowDropDownLine className="dropdown-icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button className="nav-btn left" onClick={handlePrev}>
        <RiArrowLeftSLine />
      </button>
      <button className="nav-btn right" onClick={handleNext}>
        <RiArrowRightSLine />
      </button>
      <div className="indicators">
        {slides.map((_, index) => (
          <div
            key={index}
            className={`indicator ${index === currentIndex ? "active" : ""}`}
            onClick={() => setCurrentIndex(index)}
          ></div>
        ))}
      </div>
    </section>
  );
};

export default Carousel;
